/* GPT.css */

/* Style for the entire app */
.GPT {
  text-align: center;
  margin-top: -9em;
}

/* Header styles */

/* Centered content within the header */
.GPT-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: white;
  color: white;
}

/* Chat container styles */
.chat-container {
  background-color: #2d2234;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

/* Chat history styles */
.chat-history {
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
  margin-bottom: 20px;
  min-height: 500px;
  max-height: 500px;
  flex-grow: 1; 
  text-align: left;
  max-width: 1000px;
}

/* Message styles */
.message {
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
  word-wrap: break-word;
  font-size: 15px; 
  white-space: pre-wrap; 
}

.user {
  background-color: #394a61;
  color: #fff;
  font-size: 15px; 
}

.assistant {
  background-color: #d9eadd;
  /* background-color: #cad6e8; */
  color: #000;
  font-size: 15px; 
}

/* Message gptinput form styles */
.message-gptinput-form {
  display: flex;
  align-items: center; 
  font-size: 15px; 
}

.send-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 15px;
  margin-left: 8px; 
}

/* .send-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.send-button:hover {
  background-color: #0056b3;
} */

/* Centered header text */
h1 {
  margin-bottom: 10px;
}

/* Centered header text with color */
h1,
h2 {
  color: #007bff;
  text-align: center;
}

.clear-button {
  background-color: #641e11;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 15px;
  margin-left: 8px; 
}

.clear-button:hover {
  background-color: #e53728; /* Darker red color on hover */
}

.beta-text {
  font-size: 12px;
  color: #FFA500;
}

.bold-keyword {
  font-weight: 700;
  font-style: italic;
}

