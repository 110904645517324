.app {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
}

.button {
  background-color: #0077ff;
  color: #fff;
  border: none;
  padding: 10px 10px;
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 8px;
}

.submitButton {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 8px;
}
/* left */
.button-container1 {
  display: flex;
}

.button-container1 button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.button-container1 button:hover {
  background-color: #0086b3;
}

/* right */
.button-container {
  display: flex;
  justify-content: flex-end;
}

.button-container button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.button-container button:hover {
  background-color: #004080;
}

.popup-content p {
  font-size: 15px;
  margin-bottom: 8px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  max-width: 510px;
  z-index: 1001
}

.popup-title {
  color: #333;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.close-icon {
  cursor: pointer;
  color: #000; 
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
}

h2 {
  margin-top: 0;
}

input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.dropdown {
  margin-top: 20px;
}

select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.blinking-text {
  animation: blink 2s linear infinite; /* 2 seconds animation with linear timing function and infinite loop */
}

