.fractional-unit-display2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: .8em; /* Adjust font size as needed */
  margin-top: -1em;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.line {
  border-top: 1px solid black;
  width: 25px; /* Make sure the line spans the width of the container */
  margin: 0.5em 0; /* Adjust spacing above and below the line */
}

.numerator {
  margin-bottom: -0.5em; /* Adjust spacing between numerator and line */
}

.denominator {
  margin-top: -0.5em; /* Adjust spacing between line and denominator */
}